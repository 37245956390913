// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-calender-js": () => import("./../../../src/pages/calender.js" /* webpackChunkName: "component---src-pages-calender-js" */),
  "component---src-pages-consultant-js": () => import("./../../../src/pages/consultant.js" /* webpackChunkName: "component---src-pages-consultant-js" */),
  "component---src-pages-customers-js": () => import("./../../../src/pages/customers.js" /* webpackChunkName: "component---src-pages-customers-js" */),
  "component---src-pages-employees-js": () => import("./../../../src/pages/employees.js" /* webpackChunkName: "component---src-pages-employees-js" */),
  "component---src-pages-employees-map-js": () => import("./../../../src/pages/employees-map.js" /* webpackChunkName: "component---src-pages-employees-map-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-docs-template-js": () => import("./../../../src/templates/docs-template.js" /* webpackChunkName: "component---src-templates-docs-template-js" */),
  "component---src-templates-employee-template-js": () => import("./../../../src/templates/employee-template.js" /* webpackChunkName: "component---src-templates-employee-template-js" */)
}

